import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch, FormControlLabel, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const EditAccountSettingsModal = ({ open, onClose, user, onSave }) => {
  const [publicProfile, setPublicProfile] = useState(user.perfilPublico);
  const [accountType, setAccountType] = useState(user.tipoDeConta);

  const handleSubmit = () => {
    onSave({
      perfilPublico: publicProfile,
      tipoDeConta: accountType,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Configurações de Conta</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={publicProfile}
              onChange={(e) => setPublicProfile(e.target.checked)}
              name="publicProfile"
              color="primary"
            />
          }
          label="Perfil Público"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="account-type-label">Tipo de Conta</InputLabel>
          <Select
            labelId="account-type-label"
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
            label="Tipo de Conta"
          >
            <MenuItem value="Proprietario">Proprietário</MenuItem>
            <MenuItem value="Cliente">Cliente</MenuItem>
            <MenuItem value="Suporte">Suporte</MenuItem>
            <MenuItem value="Professor">Professor</MenuItem>
            <MenuItem value="Administrador">Administrador</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAccountSettingsModal;