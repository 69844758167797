// src/services/notificationService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Interceptor para adicionar o cabeçalho de autorização automaticamente
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  if (token && idToken) {
    config.headers['Authorization'] = `Bearer ${token} ${idToken}`;
  } else if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API error:', error.message, error);
    throw error;
  }
);

const notificationService = {
  fetchNotifications: async (userId) => {
    try {
      const response = await api.get(`/api/notifications/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      throw error;
    }
  },

  markAsRead: async (userId, notificationId, type) => {
    try {
      const response = await api.post(`/api/notifications/${userId}/markAsRead`, {
        notificationId,
        type
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response);
      return response.data;
    } catch (error) {
      console.error('Error marking notification as read:', error);
      throw error;
    }
  }
}

export default notificationService;