// services/dashboardService.js
import caixinhaService from './caixinhaService';
import notificationService from './notificationService';
import messageService from './messageService';
import connectionService from './connectionService';

const getDashboardData = async (userId) => {
  try {
    const [
      caixinhas,
      notificationsResponse,
      messages,
      connectionsResponse
    ] = await Promise.all([
      caixinhaService.getCaixinhas(),
      notificationService.fetchNotifications(userId),
      messageService.getMessages(userId),
      connectionService.getConnectionsByUserId(userId)
    ]);

    const { privateNotifications, globalNotifications } = notificationsResponse;
    const { friends, bestFriends } = connectionsResponse;

    const notifications = [
      ...privateNotifications.map((notification) => ({
        id: notification.id,
        conteudo: notification.message,
        lida: notification.lida,
        eventoId: notification.eventoId
      })),
      ...globalNotifications.map((notification) => ({
        id: notification.id,
        conteudo: notification.message,
        lida: notification.lida,
        eventoId: notification.eventoId
      }))
    ];

    const formattedCaixinhas = caixinhas.length
      ? caixinhas
      : [{ message: 'Você ainda não possui caixinhas registradas' }];

    const formattedNotifications = notifications.length
      ? notifications
      : [{ message: 'Você ainda não possui notificações' }];

    const formattedMessages = messages.length
      ? messages
      : [{ message: 'Você ainda não possui mensagens' }];

    const formattedConnections = friends.length || bestFriends.length
      ? { friends, bestFriends }
      : { friends: [{ message: 'Você ainda não possui amigos ativos' }], bestFriends: [{ message: 'Você ainda não possui melhores amigos' }] };

    return {
      caixinhas: formattedCaixinhas,
      notifications: formattedNotifications,
      messages: formattedMessages,
      connections: formattedConnections,
    };
  } catch (error) {
    throw new Error(`Erro ao buscar dados do dashboard: ${error.response ? error.response.data : error.message}`);
  }
};

export default getDashboardData;