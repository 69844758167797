import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Interceptor para adicionar o cabeçalho de autorização automaticamente
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  if (token && idToken) {
    config.headers['Authorization'] = `Bearer ${token} ${idToken}`;
  } else if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

const caixinhaService = {
  getCaixinhas: async () => {
    const response = await api.get('/api/caixinha');
    return response.data;
  },

  getCaixinhaById: async (id) => {
    const response = await api.get(`/api/caixinha/${id}`);
    return response.data;
  },

  createCaixinha: async (data) => {
    const response = await api.post('/api/caixinha', data);
    return response.data;
  },

  updateCaixinha: async (id, data) => {
    const response = await api.put(`/api/caixinha/${id}`, data);
    return response.data;
  },

  deleteCaixinha: async (id) => {
    const response = await api.delete(`/api/caixinha/${id}`);
    return response.data;
  },

  addContribuicao: async (data) => {
    const response = await api.post('/api/caixinha/contribuicao', data);
    return response.data;
  },

  getContribuicoes: async (id) => {
    const response = await api.get(`/api/caixinha/${id}/contribuicoes`);
    return response.data;
  }
}

export default caixinhaService;