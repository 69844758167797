// src/components/Layout/InvitationSendModal.js
import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, TextField, Button, Switch, FormControlLabel } from '@mui/material';
import inviteService from '../../services/inviteService';
import { GiPassport } from "react-icons/gi";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const InvitationSendModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [shareProfile, setShareProfile] = useState(false);

  const handleSendInvite = async () => {
    if (agreeTerms && shareProfile) {
      try {
        await inviteService.generateInvite(email);
        handleClose();
      } catch (error) {
        console.error('Erro ao enviar convite:', error.message);
      }
    } else {
      alert('Por favor, concorde com os termos e permita o compartilhamento do perfil.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
      <Typography id="modal-title" variant="h6" component="h2">
    <IconButton size="large">
      <GiPassport />
    </IconButton>
    Enviar Convite
  </Typography>
  <Typography variant="body1" paragraph>
    Ao enviar um convite, você concorda com as seguintes regras:
    <ul>
      <li>O convite só pode ser reenviado 1 hora após o primeiro envio.</li>
      <li>O convidado terá 5 dias, a contar da data do recebimento do convite, para validá-lo. Caso contrário, o convite expirará.</li>
      <li>Nenhuma informação sobre o usuário já registrado será revelada até que ele confirme que deseja se conectar.</li>
    </ul>
  </Typography>
        <TextField
          fullWidth
          label="Email do Amigo"
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormControlLabel
    style={{ marginTop: 30 }}
    control={<Switch checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} />}
    label="Ao enviar um convite você concorda com os termos de uso e a política de privacidade da Elos."
  />
        <FormControlLabel
          control={<Switch checked={shareProfile} onChange={(e) => setShareProfile(e.target.checked)} />}
          label="O amigo que receberá o convite terá acesso ao seu nome e foto de perfil."
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendInvite}
          style={{ marginTop: 30}}
          disabled={!agreeTerms || !shareProfile}
        >
          Enviar Convite
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          style={{ marginTop: 30}}
        >
          Cancelar
        </Button>
        <Button
    variant="text"
    color="primary"
    href="/termos-de-uso"
    target="_blank"
    rel="noopener noreferrer"
  >
    Ver Termos de Uso
  </Button>
  <Button
    variant="text"
    color="primary"
    href="/politica-de-privacidade"
    target="_blank"
    rel="noopener noreferrer"
  >
    Ver Política de Privacidade
  </Button>
  <Typography variant="body2" color="textSecondary" align="center">
    © 2023 ElosCloud. Todos os direitos reservados.
  </Typography>
      </Box>
      
    </Modal>
  );
};

export default InvitationSendModal;