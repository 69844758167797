import React, { useState, useEffect } from 'react';
import { Grid, Typography, Avatar, Card, CardContent, Tooltip, Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { GiTyre } from "react-icons/gi";
import { getUserById, updateUser } from '../../services/userService';
import inviteService from '../../services/inviteService';
import { useAuth } from '../../context/AuthContext';
import EditPersonalInfoModal from './EditPersonalInfoModal';
import EditInterestsModal from './EditInterestsModal';
import EditAccountSettingsModal from './EditAccountSettingsModal';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [invitations, setInvitations] = useState(null)
  const [openPersonalInfoModal, setOpenPersonalInfoModal] = useState(false);
  const [openInterestsModal, setOpenInterestsModal] = useState(false);
  const [openAccountSettingsModal, setOpenAccountSettingsModal] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchUser = async () => {
      const userId = currentUser?.uid;
      if (userId) {
        try {
          const response = await getUserById(currentUser.uid);
          setUser(response);
          const invitationsData = await inviteService.getSentInvitations(userId);
          setInvitations(invitationsData);
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    };

    fetchUser();
  }, [currentUser]);

  const handleSavePersonalInfo = async (updatedInfo) => {
    try {
      const updatedUser = await updateUser(currentUser.uid, updatedInfo);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update user info:', error);
    }
  };

  const handleSaveInterests = async (updatedInterests) => {
    try {
      const updatedUser = await updateUser(currentUser.uid, updatedInterests);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update user interests:', error);
    }
  };

  const handleSaveAccountSettings = async (updatedSettings) => {
    try {
      const updatedUser = await updateUser(currentUser.uid, updatedSettings);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update account settings:', error);
    }
  };

  if (!user) {
    return <Typography>Loading...</Typography>;
  }

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('pt-BR', options);
  };

  return (
    <Box p={3}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box textAlign="center">
            <Avatar
              src={user.fotoDoPerfil}
              alt={user.nome}
              sx={{ width: 150, height: 150, margin: 'auto' }}
            />
            <Typography variant="h5" component="h2" mt={2}>
              {user.nome}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {user.email}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {user.descricao}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Criado em {formatDate(user.dataCriacao)}
            </Typography>
            <Button variant="outlined" startIcon={<EditIcon />} sx={{ mt: 2 }} onClick={() => setOpenPersonalInfoModal(true)}>
              Editar Perfil
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Interesses Pessoais
                    <IconButton size="small" sx={{ ml: 1 }} onClick={() => setOpenInterestsModal(true)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                  {user.interessesPessoais?.map((interesse, index) => (
                    <Tooltip key={index} title={`Mais informações sobre ${interesse}`}>
                      <Typography variant="body2" component="span" sx={{ mr: 1 }}>
                        {interesse}
                      </Typography>
                    </Tooltip>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Interesses de Negócios
                    <IconButton size="small" sx={{ ml: 1 }} onClick={() => setOpenInterestsModal(true)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                  {user.interessesNegocios?.map((interesse, index) => (
                    <Tooltip key={index} title={`Mais informações sobre ${interesse}`}>
                      <Typography variant="body2" component="span" sx={{ mr: 1 }}>
                        {interesse}
                      </Typography>
                    </Tooltip>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="div">
                    ElosCoins
                  </Typography>
                  <Typography variant="body1" color="primary">
                  <GiTyre />
                    {user.saldoElosCoins} ELC
                  </Typography>
                  <Button variant="outlined" sx={{ mt: 1 }}>
                    Ver Extrato
                  </Button>
                  <Button variant="outlined" sx={{ mt: 1, ml: 1 }}>
                    Comprar ElosCoins
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Configurações de Conta
                    <IconButton size="small" sx={{ ml: 1 }} onClick={() => setOpenAccountSettingsModal(true)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Visibilidade:</strong> {user.perfilPublico ? 'Público' : 'Privado'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Tipo de Conta:</strong> {user.tipoDeConta}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Atividades Recentes
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Amigos:</strong> {user.amigos?.length || 0}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Mensagens Não Lidas:</strong> {user.conversasComMensagensNaoLidas?.length || 0}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Convites Enviados:</strong> {invitations?.length || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Modais de Edição */}
      <EditPersonalInfoModal
        open={openPersonalInfoModal}
        onClose={() => setOpenPersonalInfoModal(false)}
        user={user}
        onSave={handleSavePersonalInfo}
      />
      <EditInterestsModal
        open={openInterestsModal}
        onClose={() => setOpenInterestsModal(false)}
        user={user}
        onSave={handleSaveInterests}
      />
      <EditAccountSettingsModal
        open={openAccountSettingsModal}
        onClose={() => setOpenAccountSettingsModal(false)}
        user={user}
        onSave={handleSaveAccountSettings}
      />
    </Box>
  );
};

export default Profile;