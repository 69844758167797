// src/components/Layout/UserProfileDropdown.js
import React, { useState, useEffect } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Typography, Box } from '@mui/material';
import { GiFingerPrint, GiWallet, GiModernCity, GiExitDoor } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import { getUserById } from '../../services/userService';
import notificationService from '../../services/notificationService';

const UserProfileDropdown = () => {
    const [notifications, setNotifications] = useState(null);
    const [user, setUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchUser = async () => {
            const userId = currentUser?.uid;
            if (userId) {
                try {
                    const response = await getUserById(currentUser.uid);
                    setUser(response);
                    const notificationsData = await notificationService.fetchNotifications(userId);
                    setNotifications(notificationsData);
                } catch (error) {
                    console.error('Failed to fetch user:', error);
                }
            }
        };

        fetchUser();
    }, [currentUser]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Box
                onClick={handleMenuOpen}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '0.5rem',
                    borderRadius: '8px',
                    ':hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }
                }}
            >
                <Avatar src={user?.fotoDoPerfil} alt={user?.nome} />
                <Typography variant="body1" sx={{ marginLeft: '0.5rem' }}>
                    Olá, {user?.nome}
                </Typography>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: '1rem' }}
            >
                <MenuItem disabled>
                    <Typography variant="body2">
                        {user?.email}
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate('/profile/settings');
                        handleMenuClose();
                    }}
                >
                    <IconButton size="medium" >
                    <GiFingerPrint sx={{ marginRight: '0.5rem' }} />
                    </IconButton>
                    Configurar Perfil
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate('/wallet/settings');
                        handleMenuClose();
                    }}
                >
                    <IconButton size="medium" >
                    <GiWallet sx={{ marginRight: '0.5rem' }} />
                    </IconButton>
                    Configurar Carteira
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate('/address/settings');
                        handleMenuClose();
                    }}
                >
                    <IconButton size="medium" >
                    <GiModernCity sx={{ size: 'large', marginRight: '0.5rem' }} />
                    </IconButton>
                    Configurar Endereço
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        logout();
                        navigate('/login');
                    }}
                >
                     <IconButton size="medium" >
            <GiExitDoor sx={{ marginRight: '0.5rem' }} />
            </IconButton>  
            Sair
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserProfileDropdown;