// src/components/Layout/InvitationCard.js
import React, { useState } from 'react';
import moment from 'moment/moment';
import { Typography, Tooltip, IconButton, Card, CardContent, CardActions, Box } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { GiPassport, GiInvisible, GiCancel, GiBackForth, GiSandsOfTime, GiCheckMark } from "react-icons/gi";
import InvitationSendModal from './InvitationSendModal';

const InvitationCard = ({ invitation, onCancel, onResend }) => {
  const [showCode, setShowCode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const getStatusMessage = (status, date, email) => {
    switch (status) {
      case 'pending':
        return 'Convite enviado, avisaremos quando seu amigo aceitar!';
      case 'used':
        return `Boas notícias! Seu amigo aceitou o convite e já está registrado na plataforma desde ${new Date(date).toLocaleDateString()}.`;
      case 'canceled':
        return `Você cancelou esse convite em: ${new Date(date).toLocaleDateString()}`;
      case 'expired':
        return `Que pena, esse convite expirou. Solicite um novo convite para ${email}`;
      default:
        return '';
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Convite Pendente.';
      case 'used':
        return "Convite Aceito.";
      case 'canceled':
        return "Convite Cancelado.";
      case 'expired':
        return 'Convite Expirou.';
      default:
        return '';
    }
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  // Atualizando o status do convite conforme a data de criação e status atual
  let status = invitation.status;
  if (invitation.status === 'pending' && moment(invitation.createdAt).isBefore(moment().subtract(5, 'days'))) {
    status = 'expired';
  }
  if (invitation.status === 'canceled') {
    status = 'expired';
  }

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              Convidado: {invitation.email}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Status: {getStatus(status)}
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary">
            Convidado em: {moment(invitation.createdAt).format('DD [de] MMMM [de] YYYY')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Enviado há {moment(invitation.createdAt).fromNow()}
          </Typography>
          {status === 'pending' && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" component="div">
                Código do Convite: <code>{showCode ? invitation.id : invitation.id?.slice(0, 6) + '...'}</code>
              </Typography>
              <Tooltip title={showCode ? "Esconder Código" : "Mostrar Código"}>
                <IconButton size="small" onClick={() => setShowCode(!showCode)}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Typography variant="body2" color="textSecondary">
            {getStatusMessage(status, invitation.createdAt, invitation.email)}
          </Typography>
        </CardContent>
        <CardActions sx={{ flexDirection: 'column', justifyContent: 'center', gap: 1 }}>
          {status === 'pending' ? (
            <>
              <Tooltip title="Convite Pendente">
                <IconButton>
                  <GiSandsOfTime style={{ color: 'orange' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reenviar Convite">
                <IconButton onClick={() => onResend(invitation.id)}>
                  <GiBackForth />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar Convite">
                <IconButton onClick={() => onCancel(invitation.id)}>
                  <GiCancel />
                </IconButton>
              </Tooltip>
            </>
          ) : status === 'expired' ? (
            <Tooltip title="Convite Expirado">
              <IconButton disabled>
                <GiInvisible style={{ color: 'gray' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Convite Aceito">
              <IconButton>
                <GiCheckMark style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </Card>
      <InvitationSendModal open={modalOpen} handleClose={handleModalClose} />
    </>
  );
};

export default InvitationCard;