// src/components/Layout/Layout.js
import React from 'react';
import { Box, Toolbar } from '@mui/material';
import TopNavbar from './TopNavBar';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <TopNavbar />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
