// src/components/Layout/TopNavbar.js
import React, { useEffect, useState} from 'react';
import { AppBar, Toolbar, Box, useTheme } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import { GiLockedChest, GiThreeFriends, GiConversation, GiRingingBell, GiShoppingCart } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import NavItem from './NavItem';
import UserProfileDropdown from './UserProfileDropdown';
import notificationService from '../../services/notificationService';
import { useAuth } from '../../context/AuthContext';

const imgLogo = process.env.REACT_APP_PLACE_HOLDER_IMG;

const TopNavbar = () => {
    const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(null)
  const theme = useTheme();

  useEffect(() => {
    const fetchUser = async () => {
      const userId = currentUser?.uid;
      if (userId) {
        try {
          const notificationsData = await notificationService.fetchNotifications(userId);
          setNotifications(notificationsData);
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    };

    fetchUser();
  }, [currentUser]);

  return (
    <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1, padding: '0.5rem', [theme.breakpoints.down('sm')]: { padding: '0.5rem' } }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <NavItem
            icon={<img src={imgLogo} alt="Logo" style={{ height: '80px', margin: '-15px' }} />}
            onClick={() => navigate('/dashboard')}
            tooltip="Home"
          />
        </Box>
        <NavItem icon={<>
            <GiRingingBell />
            {Notifications.length > 0 && (
                <span className='badge'>{notifications.length}</span>
            )}
            </>} onClick={() => navigate('/notifications')} tooltip="Notificações" />
        <NavItem icon={<GiConversation />} onClick={() => navigate('/messages')} tooltip="Conversas" />
        <NavItem icon={<GiLockedChest />} onClick={() => navigate('/caixinhas')} tooltip="Caixinhas" />
        <NavItem icon={<GiThreeFriends />} onClick={() => navigate('/connections')} tooltip="Amigos" />
        <NavItem icon={<GiShoppingCart />} onClick={() => navigate('/shop')} tooltip="Loja" />
        <UserProfileDropdown />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;