import React, { useEffect, useState } from 'react';
import { Container, CardActions, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Button, Typography, Grid, Card, CardContent, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import getDashboardData from '../../services/dashboardService';
import notificationService from '../../services/notificationService';
import { MessageSharp, FavoriteSharp, PersonSharp, ThumbUpSharp, CardGiftcardSharp, InfoSharp } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Profile from '../Profiles/Profile';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) {
        navigate('/login');
        return;
      }
      setLoading(true);
      try {
        const response = await getDashboardData(currentUser.uid);
        if (response) {
          setDashboardData(response);
          toast.success('Dashboard data loaded successfully!');
        } else {
          toast.error('Failed to load dashboard data');
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        toast.error('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser, navigate]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!dashboardData) {
    return (
      <Container>
        <Typography variant="h5">No dashboard data available</Typography>
      </Container>
    );
  }

  const handleMarkAsRead = async (notificationId, type) => {
    const userId = currentUser?.uid;
    if (!userId) {
      console.error('User ID is undefined');
      return;
    }
    try {
      await notificationService.markAsRead(userId, notificationId, type);
      console.log('Notification marked as read');
      // Optionally, refresh notifications or update the state
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Dashboard
      </Typography>
      <Profile />
      <Grid container spacing={3}>
        {dashboardData.caixinhas && dashboardData.caixinhas.length > 0 ? (
          dashboardData.caixinhas.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Caixinha: {item.name}</Typography>
                  <Typography>{item.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">No caixinhas data available</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {dashboardData.notifications && dashboardData.notifications.length > 0 ? (
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, margin: 1 }}>
              <CardContent>
                <Typography variant="h5">
                  {dashboardData.notifications.filter((n) => !n.lida).length > 0
                    ? `Você tem ${dashboardData.notifications.filter((n) => !n.lida).length} novas notificações`
                    : 'Nenhuma nova notificação'}
                </Typography>
                {dashboardData.notifications.slice(0, 3).map((notification) => (
                  <Card key={notification.id} sx={{ marginBottom: 2 }}>
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {notification.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {notification.type === 'reaction' ? <ThumbUpSharp /> : notification.type === 'gift' ? <CardGiftcardSharp /> : <InfoSharp />}
                        {notification.content}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {notification.lida ? `Lido há ${notification.lidaAt}` : `Recebido há ${notification.receivedAt}`}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary">
                        Ver Evento
                      </Button>
                      <Button size="small" color="primary" onClick={() => handleMarkAsRead(currentUser.uid, notification.id, notification.type)}>
                        {notification.lida ? 'Desmarcar como lido' : 'Marcar como lido'}
                      </Button>
                      <Button size="small" color="primary">
                        Ver Calendário
                      </Button>
                    </CardActions>
                  </Card>
                ))}
                <Button variant="contained" color="primary" onClick={() => navigate('/notifications')}>
                  {dashboardData.notifications.filter((n) => !n.lida).length > 0
                    ? 'Ver todas as notificações'
                    : 'Ver histórico de notificações'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, margin: 1 }}>
              <CardContent>
                <Typography variant="h5">Nenhuma notificação</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {dashboardData.messages && dashboardData.messages.length > 0 ? (
          dashboardData.messages.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Message from {item.userId}</Typography>
                  <Typography>{item.content}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">No messages data available</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Amigos</Typography>
              <List>
                {dashboardData.connections.friends.map((connection, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar src={connection.fotoDoPerfil} />
                    </ListItemAvatar>
                    <ListItemText primary={connection.nome} secondary={`Connected for ${connection.connectionDuration}`} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="view-profile">
                        <PersonSharp />
                      </IconButton>
                      <IconButton edge="end" aria-label="send-message">
                        <MessageSharp />
                      </IconButton>
                      <IconButton edge="end" aria-label="make-best-friend">
                        <FavoriteSharp />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {dashboardData.connections && dashboardData.connections.bestFriends && dashboardData.connections.bestFriends.length > 0 ? (
          dashboardData.connections.bestFriends.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Best Friend: {item.nome}</Typography>
                  <Typography>{item.email}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">No best friends data available</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Dashboard;