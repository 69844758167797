import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  
  // Interceptor para adicionar o cabeçalho de autorização automaticamente
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    const idToken = localStorage.getItem('idToken');
  
    if (token && idToken) {
      config.headers['Authorization'] = `Bearer ${token} ${idToken}`;
    } else if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

const inviteService = {
  generateInvite: async (email) => {
    try {
      const response = await api.post('/api/invite/generate', { email });
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao gerar convite: ${error.response ? error.response.data : error.message}`);
    }
  },

  validateInvite: async (inviteToken) => {
    try {
      const response = await api.post('/api/invite/validate', { inviteToken });
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao validar convite: ${error.response ? error.response.data : error.message}`);
    }
  },

  invalidateInvite: async (inviteToken) => {
    try {
      const response = await api.post('/api/invite/invalidate', { inviteToken });
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao invalidar convite: ${error.response ? error.response.data : error.message}`);
    }
  },

  cancelInvitation: async(inviteToken) => {
  try {
    const response = await api.put('/api/invite/cancel/', { inviteToken });
    return response.data;
  } catch (error) {
    throw new Error(`Erro ao invalidar convite: ${error.response ? error.response.data : error.message}`);
  }
},

  getSentInvitations: async (userId) => {
    try {
      const response = await api.get('/api/invite/sent/', { userId });
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao invalidar convite: ${error.response ? error.response.data : error.message}`);
    }
  }


};

export default inviteService;