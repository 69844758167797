//src/components/Connections/FriendCard.jsx
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Box, Link, Tooltip } from '@mui/material';
import { GiStarFormation, GiTelepathy, GiConversation, GiTrashCan, GiPresent } from "react-icons/gi";
import connectionService from '../../services/connectionService';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const FriendCard = ({ friend }) => {
  const { currentUser } = useAuth();
  const [isBestFriend, setIsBestFriend] = useState(false);
  const [bestFriends, setBestFriends] = useState([]);
  const [friends, setFriends] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConnections = async () => {
      const userId = currentUser?.uid;
      if (userId) {
        try {
          const { friends, bestFriends } = await connectionService.getConnectionsByUserId(userId);
          setFriends(friends);
          setBestFriends(bestFriends);
          const isBestFriend = bestFriends.includes(friend.id);
          setIsBestFriend(isBestFriend);
        } catch (error) {
          console.error('Failed to fetch connections:', error);
        }
      }
    };

    fetchConnections();
  }, [currentUser, friend]);

  const handleDelete = async () => {
    try {
      await connectionService.deleteActiveConnection(friend.id);
      // Update the friend list after deletion
    } catch (error) {
      console.error('Failed to delete connection:', error);
    }
  };

  const handleToggleBestFriend = async () => {
    try {
      if (isBestFriend) {
        // Remove from best friends
        await connectionService.removeBestFriend(currentUser.uid, friend.id);
        setBestFriends(bestFriends.filter((id) => id!== friend.id));
      } else {
        // Add to best friends
        await connectionService.addBestFriend(currentUser.uid, friend.id);
        setBestFriends([...bestFriends, friend.id]);
      }
      setIsBestFriend(!isBestFriend);
    } catch (error) {
      console.error('Failed to update best friends:', error);
    }
  };

  return (
    <Card variant="outlined" sx={{ maxWidth: 345 }}>
      <Link href={`/profile/${friend.id}`} underline="none">
        <CardMedia
          component="img"
          height="140"
          image={friend.fotoDoPerfil}
          alt={friend.nome}
        />
      </Link>
      <CardContent>
        <Link href={`/profile/${friend.id}`} underline="none">
          <Typography variant="h6" component="div">
            {friend.nome}
          </Typography>
        </Link>
        <Typography variant="body2" color="textSecondary">
          Interesses Pessoais: {friend.interessesPessoais.join(', ')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Interesses de Negócios: {friend.interessesNegocios.join(', ')}
        </Typography>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Tooltip title="Ver amizade">
              <IconButton color="primary" onClick={() => console.log('Ver amizade')}>
                <GiTelepathy />
              </IconButton>
            </Tooltip>
            <Tooltip title="Desfazer amizade">
              <IconButton color="secondary" onClick={handleDelete}>
                <GiTrashCan />
              </IconButton>
            </Tooltip>
            <Tooltip title="Iniciar uma conversa">
              <IconButton color="default" onClick={() => console.log('Send message')}>
                <GiConversation />
              </IconButton>
            </Tooltip>
            <Tooltip title="Enviar presente">
              <IconButton color="primary" onClick={() => console.log('Invite to Caixinha')}>
                <GiPresent />
              </IconButton>
            </Tooltip>
            <Tooltip title={isBestFriend? "Retirar dos melhores amigos" : "Tornar melhor amigo"}>
      <IconButton color={isBestFriend? "secondary" : "default"} onClick={handleToggleBestFriend}>
        <GiStarFormation style={{ color: isBestFriend? 'gold' : 'inherit' }} />
      </IconButton>
    </Tooltip>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FriendCard;