import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor para adicionar o cabeçalho de autorização automaticamente
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  if (token && idToken) {
    config.headers['Authorization'] = `Bearer ${token} ${idToken}`;
  } else if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export const register = async (email, password, inviteCode) => {
  console.log('register: ', email, password, inviteCode);
  const response = await api.post('api/auth/register', { email, password, inviteCode });
  console.log('register response: ', response.data);
  return response.data;
};

export const login = async (email, password) => {
  console.log('login: ', email, password);
  const response = await api.post('api/auth/login', { email, password });
  console.log('login response: ', response.data);
  return response.data;
};

export const logout = async () => {
  console.log('logout');
  const response = await api.post('api/auth/logout');
  console.log('logout response: ', response.data);
  return response.data;
};

export const loginWithProvider = async (idToken, provider) => {
  console.log('loginWithProvider: ', idToken, provider);
  const response = await api.post('api/auth/login-with-provider', { idToken, provider });
  console.log('loginWithProvider response: ', response.data);
  return response.data;
};

export const registerWithProvider = async (provider, inviteCode) => {
  console.log('registerWithProvider: ', provider, inviteCode);
  const response = await api.post('api/auth/register-with-provider', { provider, inviteCode });
  console.log('registerWithProvider response: ', response.data);
  return response.data;
};

export const resendVerificationEmail = async (email) => {
  console.log('resendVerificationEmail: ', email);
  const response = await api.post('api/auth/resend-verification-email', { email });
  console.log('resendVerificationEmail response: ', response.data);
  return response.data;
};

export const getCurrentUserFromBackend = async () => {
  try {
    const response = await api.get('api/auth/me');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar usuário atual:', error);
    throw error;
  }
};