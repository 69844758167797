// services/userService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Interceptor para adicionar o cabeçalho de autorização automaticamente
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const idToken = localStorage.getItem('idToken');

  if (token && idToken) {
    config.headers['Authorization'] = `Bearer ${token} ${idToken}`;
  } else if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getUsers = async () => {
  const response = await api.get('/api/users');
  return response.data;
};

export const getUserById = async (id) => {
  const response = await api.get(`/api/users/${id}`);
  return response.data;
};

export const addUser = async (user) => {
  const response = await api.post('/api/users/add-user', user);
  return response.data;
};

export const updateUser = async (id, user) => {
  const response = await api.put(`/api/users/update-user/${id}`, user);
  return response.data;
};

export const deleteUser = async (id) => {
  const response = await api.delete(`/api/users/delete-user/${id}`);
  return response.data;
};

export default {
  getUsers,
  getUserById,
  addUser,
  updateUser,
  deleteUser
};