// src/routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/Pages/HomePage';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import Profile from './components/Profiles/Profile';
import NotificationHistory from './components/Notification/NotificationHistory';
import PrivateRoute from './components/Auth/PrivateRoute';
import FriendsPage from './components/Connections/FriendsPage';
import Layout from './components/Layout/Layout';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard" element={<PrivateRoute element={<Layout><Dashboard /></Layout>} />} />
      <Route path="/notifications" element={<PrivateRoute element={<Layout><NotificationHistory /></Layout>} />} />
      <Route path="/profile" element={<PrivateRoute element={<Layout><Profile /></Layout>} />} />
      <Route path="/connections" element={<PrivateRoute element={<Layout><FriendsPage /></Layout>} />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
};

export default AppRoutes;