import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../firebaseConfig';
import { getCurrentUserFromBackend, logout as authServiceLogout } from '../services/authService';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("accessToken", token);
        try {
          const backendUser = await getCurrentUserFromBackend(token);
          setCurrentUser(backendUser);
        } catch (error) {
          console.error(error);
        }
      } else {
        setCurrentUser(null);
        localStorage.removeItem("accessToken");
      }
    });

    return () => unsubscribe();
  }, []);

  const signInWithProvider = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      console.log('ID Token after sign in:', idToken);
      localStorage.setItem("accessToken", idToken);
      setCurrentUser(result.user);
      toast.success('Login bem-sucedido.');
      navigate('/dashboard');
    } catch (error) {
      console.error('Erro ao fazer login com provedor:', error);
      toast.error('Erro ao fazer login.');
    }
  };

  const signInWithGoogle = () => signInWithProvider(googleProvider);
  const signInWithFacebook = () => signInWithProvider(facebookProvider);
  const signInWithMicrosoft = () => signInWithProvider(microsoftProvider);

  const logoutUser = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      await authServiceLogout(token); 
      await signOut(auth);
      localStorage.clear();
      setCurrentUser(null);
      toast.success('Logout realizado com sucesso!');
      navigate('/login');
    } catch (error) {
      console.error('Erro ao tentar deslogar:', error);
      toast.error('Erro ao tentar deslogar!');
    }
  };

  const value = {
    currentUser,
    setCurrentUser,
    signInWithGoogle,
    signInWithFacebook,
    signInWithMicrosoft,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };