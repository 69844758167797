import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Interceptor para adicionar o cabeçalho de autorização automaticamente
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    const idToken = localStorage.getItem('idToken');
  
    if (token && idToken) {
      config.headers['Authorization'] = `Bearer ${token} ${idToken}`;
    } else if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

const connectionService = {
  // Active Connections
  getConnectionsByUserId: async (userId) => {
    const response = await api.get(`/api/connections/active/user/${userId}`);
    return response.data;
  },

  updateActiveConnection: async (id, data) => {
    const response = await api.put(`/api/connections/active/${id}`, data);
    return response.data;
  },

  deleteActiveConnection: async (id) => {
    const response = await api.delete(`/api/connections/active/${id}`);
    return response.data;
  },

  createActiveConnection: async (data) => {
    const response = await api.post(`/api/connections/active`, data);
    return response.data;
  },

  // Inactive Connections
  getInactiveConnectionById: async (id) => {
    const response = await api.get(`/api/connections/inactive/${id}`);
    return response.data;
  },

  updateInactiveConnection: async (id, data) => {
    const response = await api.put(`/api/connections/inactive/${id}`, data);
    return response.data;
  },

  deleteInactiveConnection: async (id) => {
    const response = await api.delete(`/api/connections/inactive/${id}`);
    return response.data;
  },

  createInactiveConnection: async (data) => {
    const response = await api.post(`/api/connections/inactive`, data);
    return response.data;
  },

  // Requested Connections
  getRequestedConnectionById: async (id) => {
    const response = await api.get(`/api/connections/requested/${id}`);
    return response.data;
  },

  updateRequestedConnection: async (id, data) => {
    const response = await api.put(`/api/connections/requested/${id}`, data);
    return response.data;
  },

  deleteRequestedConnection: async (id) => {
    const response = await api.delete(`/api/connections/requested/${id}`);
    return response.data;
  },

  createRequestedConnection: async (data) => {
    const response = await api.post(`/api/connections/requested`, data);
    return response.data;
  }
};

export default connectionService;