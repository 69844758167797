// src/components/Auth/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
  Button, 
  Container, 
  TextField, 
  Typography, 
  Box, 
  Modal, 
  IconButton, 
  Tooltip 
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import Microsoft from '@mui/icons-material/Microsoft';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [interestEmail, setInterestEmail] = useState('');
  const navigate = useNavigate();
  const { signInWithEmail, signInWithGoogle, signInWithFacebook, signInWithMicrosoft } = useAuth();

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmail(email, password);
      toast.success('Login bem-sucedido!');
      navigate('/dashboard');
    } catch (error) {
      toast.error(`Erro ao fazer login: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleProviderLogin = async (provider) => {
    try {
      if (provider === 'google') {
        await signInWithGoogle();
      } else if (provider === 'facebook') {
        await signInWithFacebook();
      } else if (provider === 'microsoft') {
        await signInWithMicrosoft();
      }
    } catch (error) {
      toast.error(`Erro no login com provedor: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInterestSubmit = (event) => {
    event.preventDefault();
    // Handle interest email submission logic here
    toast.success('Email de interesse enviado com sucesso!');
    setInterestEmail('');
    handleCloseModal();
  };

  return (
    <Container maxWidth="lg" style={{ display: 'flex', left: 0, top: 0, bottom: 0 }}>
      <Box 
        sx={{ 
          display: { xs: 'none', md: 'block' }, 
          width: '70%', 
          height: '100vh', 
          backgroundImage: 'url(https://i.imgur.com/zxOOy0G.png)', 
          backgroundSize: 'cover', 
          backgroundPosition: 'center' 
        }} 
      />
      <Container maxWidth="sm" style={{ marginTop: '2rem', width: '30%' }}>
        <Typography variant="h5" style={{ marginTop: '2rem' }} gutterBottom>Login</Typography>
        <form onSubmit={handleEmailLogin}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="email"
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginBottom: '1rem' }}>
            Login
          </Button>
        </form>
        <Typography variant="body2" align="center" gutterBottom>ou</Typography>
        <Box display="flex" justifyContent="space-around" marginBottom="1rem">
          <Tooltip title="Login com Google">
            <IconButton onClick={() => handleProviderLogin('google')} style={{ border: '1px solid', borderColor: 'transparent' }}>
              <GoogleIcon style={{ color: '#4285F4' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Login com Facebook">
            <IconButton onClick={() => handleProviderLogin('facebook')} style={{ border: '1px solid', borderColor: 'transparent' }}>
              <FacebookIcon style={{ color: '#1877F2' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Login com Microsoft">
            <IconButton onClick={() => handleProviderLogin('microsoft')} style={{ border: '1px solid', borderColor: 'transparent' }}>
              <Microsoft style={{ color: '#00A4EF' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography variant="body2" align="center" gutterBottom style={{ color: 'gray' }}>Apenas convidados podem se cadastrar</Typography>

        <Typography variant="body2" align="center">
          <Button onClick={handleOpenModal} style={{ textTransform: 'none' }}>
            Como receber meu convite?
          </Button>
        </Typography>
        <Typography variant="body2" align="center" style={{ color: 'gray' }}>
          &copy; {new Date().getFullYear()} ElosCloud
        </Typography>
        
      </Container>
      
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box 
          sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: 400, 
            bgcolor: 'background.paper', 
            border: '2px solid #000', 
            boxShadow: 24, 
            p: 4 
          }}
        >
          <Typography variant="h6" component="h2">Como receber meu convite</Typography>
          <ol>
            <li>Solicite um convite através do nosso contato.</li>
            <li>Aguarde a confirmação por email.</li>
            <li>Verifique seu email para o link de convite.</li>
            <li>Use o link para completar seu cadastro.</li>
          </ol>
          <form onSubmit={handleInterestSubmit}>
            <TextField
              label="Seu Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={interestEmail}
              onChange={(e) => setInterestEmail(e.target.value)}
              required
              autoComplete="email"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Incluir na lista de interesse
            </Button>
          </form>
        </Box>
      </Modal>
    </Container>
  );
};

export default Login;