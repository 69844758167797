// File path: src/components/Connections/FriendsPage.jsx
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, TextField, Select, MenuItem, IconButton, Button } from '@mui/material';
import { Sort as SortIcon } from '@mui/icons-material';
import { GiHealing } from "react-icons/gi";
import connectionService from '../../services/connectionService';
import inviteService from '../../services/inviteService';
import { useAuth } from '../../context/AuthContext';
import FriendCard from './FriendCard';
import InvitationCard from '../Invites/InvitationCard';
import InvitationSendModal from '../Invites/InvitationSendModal';
import { v4 as uuidv4 } from 'uuid';

const FriendsPage = () => {
  const { currentUser } = useAuth();
  const [friends, setFriends] = useState([]);
  const [bestFriends, setBestFriends] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('name');
  const [modalOpen, setModalOpen] = useState(false);


  useEffect(() => {
    const fetchConnections = async () => {
      const userId = currentUser?.uid;
      if (userId) {
        try {
          const { friends, bestFriends } = await connectionService.getConnectionsByUserId(userId);
          setFriends(friends);
          setBestFriends(bestFriends);
          const invitationsData = await inviteService.getSentInvitations(userId);
          setInvitations(invitationsData);
        } catch (error) {
          console.error('Failed to fetch connections:', error);
        }
      }
    };

    fetchConnections();
  }, [currentUser]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleCancelInvitation = async (invitationId) => {
    try {
      await inviteService.cancelInvitation(invitationId);
      setInvitations(prevInvitations => prevInvitations.filter(inv => inv.id !== invitationId));
    } catch (error) {
      console.error('Failed to cancel invitation:', error);
    }
  };

  const handleResendInvitation = async (invitationId) => {
    try {
      await inviteService.resendInvitation(invitationId);
      // Optionally update the status of the invitation
    } catch (error) {
      console.error('Failed to resend invitation:', error);
    }
  };

  const sortedFriends = [...friends, ...bestFriends].sort((a, b) => {
    if (sort === 'name') {
      return a.nome.localeCompare(b.nome);
    } else if (sort === 'interests') {
      return a.interessesPessoais.length - b.interessesPessoais.length;
    }
    return 0;
  });

  const filteredFriends = sortedFriends.filter(friend => {
    if (filter === 'all') return true;
    if (filter === 'active' && friends.includes(friend)) return true;
    if (filter === 'inactive' && bestFriends.includes(friend)) return true;
    return false;
  });

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <>
    <Box p={3}>
      <Typography variant="h4" component="h2" gutterBottom>
        Meus Amigos
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          label="Pesquisar Amigos"
          variant="outlined"
          size="small"
          onChange={(e) => setFilter(e.target.value)}
        />
        <Box display="flex" alignItems="center">
          <Select value={filter} onChange={handleFilterChange} displayEmpty>
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="active">Ativos</MenuItem>
            <MenuItem value="inactive">Inativos</MenuItem>
            <MenuItem value="invited">Convidados</MenuItem>
          </Select>
          <Select value={sort} onChange={handleSortChange} displayEmpty>
            <MenuItem value="name">Nome</MenuItem>
            <MenuItem value="interests">Interesses</MenuItem>
          </Select>
          <IconButton>
            <SortIcon />
          </IconButton>
        </Box>
        <Button variant="contained" startIcon={<GiHealing />} onClick={handleModalOpen}>
          Convidar Amigo
        </Button>
      </Box>
      <Grid container spacing={3}>
        {filteredFriends.length > 0 ? (
          filteredFriends.map(friend => (
            <Grid item xs={12} sm={6} md={4} key={uuidv4()}>
              <FriendCard friend={friend} />
            </Grid>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            Nenhum amigo encontrado
          </Typography>
        )}
      </Grid>
      <Box mt={4}>
        <Typography variant="h5" component="h3" gutterBottom>
          Convites Enviados
        </Typography>
        {invitations.length > 0 ? (
          invitations.map(invitation => (
            <InvitationCard 
              key={invitation.id} 
              invitation={invitation} 
              onCancel={handleCancelInvitation} 
              onResend={handleResendInvitation} 
            />
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            Nenhum convite enviado
          </Typography>
        )}
      </Box>
    </Box>
    <InvitationSendModal open={modalOpen} handleClose={handleModalClose} />
    </>
  );
};

export default FriendsPage;