import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Grid } from '@mui/material';
import { Done as DoneIcon, Event as EventIcon } from '@mui/icons-material';
import notificationService from '../../services/notificationService';
import { useAuth } from '../../context/AuthContext';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_BACKEND_URL);

const NotificationHistory = () => {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = useState([]);
  
  useEffect(() => {
    const userId = currentUser?.uid;
    console.log('userID no historico de notificacoes: ', userId);
    const fetchUserNotifications = async () => {
      if (userId) {
        try {
          const { privateNotifications, globalNotifications } = await notificationService.fetchNotifications(userId);
          setNotifications([...privateNotifications, ...globalNotifications]);
        } catch (error) {
          console.error('Failed to fetch notifications:', error);
        }
      }
    };

    fetchUserNotifications();

    // Escutar eventos de atualização do usuário e leitura de notificações
    socket.on('userUpdated', (data) => {
      if (data.userId === currentUser?.uid) {
        // Atualizar dados do usuário ou mostrar uma notificação no frontend
        console.log('User updated:', data);
      }
    });

    socket.on('notificationRead', (data) => {
      if (data.userId === currentUser?.uid) {
        // Atualizar o estado das notificações
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === data.notificationId ? { ...notification, lida: true } : notification
          )
        );
      }
    });

    return () => {
      socket.off('userUpdated');
      socket.off('notificationRead');
    };
  }, [currentUser]);

  const markAsRead = async (notificationId, type) => {
    const userId = currentUser?.uid;
    if (!userId) {
      console.error('User ID is undefined');
      return;
    }
    try {
      await notificationService.markAsRead(userId, notificationId, type);
      console.log('Notification marked as read');
      // Optionally, refresh notifications or update the state
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const openEvent = (eventId) => {
    // Implementar lógica para abrir o evento que gerou a notificação
    console.log(`Open event with ID: ${eventId}`);
  };

  return (
    <Box p={3}>
      <Typography variant="h4" component="h2" gutterBottom>
        Histórico de Notificações
      </Typography>
      <Grid container spacing={3}>
        {Array.isArray(notifications) && notifications.length > 0 ? (
          notifications.map(notification => (
            <Grid item xs={12} sm={6} md={4} key={notification.id}>
              <Card variant="outlined" sx={{ backgroundColor: notification.lida ? '#f0f0f0' : '#fff' }}>
                <CardContent>
                  <Typography variant="body1" component="p">
                    {notification.conteudo}
                  </Typography>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="caption" color="textSecondary">
                      {notification.lida ? 'Lida' : 'Não Lida'}
                    </Typography>
                    <Box>
                      {!notification.lida && (
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => markAsRead(notification.id, notification.tipo)}
                        >
                          <DoneIcon />
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => openEvent(notification.eventoId)}
                      >
                        <EventIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            Nenhuma notificação disponível
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default NotificationHistory;
