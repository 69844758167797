import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  
  // Interceptor para adicionar o cabeçalho de autorização automaticamente
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    const idToken = localStorage.getItem('idToken');
  
    if (token && idToken) {
      config.headers['Authorization'] = `Bearer ${token} ${idToken}`;
    } else if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

const messageService = {
  // Get all messages
  getMessages: async () => {
    try {
      const response = await api.get('/api/messages');
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao retornar mensagens: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Create a new message
  createMessage: async (message) => {
    try {
      const response = await api.post('/api/messages', message);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao criar mensagem: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Get a message by ID
  getMessageById: async (uidRemetente, uidDestinatario, id) => {
    try {
      const response = await api.get(`/api/messages/${uidRemetente}_${uidDestinatario}/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao retornar mensagem: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Update a message by ID
  updateMessage: async (uidRemetente, uidDestinatario, id, message) => {
    try {
      const response = await api.put(`/api/messages/${uidRemetente}_${uidDestinatario}/${id}`, message);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao atualizar mensagem: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Delete a message by ID
  deleteMessage: async (uidRemetente, uidDestinatario, id) => {
    try {
      const response = await api.delete(`/api/messages/${uidRemetente}_${uidDestinatario}/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao deletar mensagem: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Get messages by user IDs
  getMessagesByUser: async (uidRemetente, uidDestinatario) => {
    try {
      const response = await api.get(`/api/messages/user/${uidRemetente}_${uidDestinatario}`);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao retornar mensagens: ${error.response ? error.response.data : error.message}`);
    }
  },
};

export default messageService;