import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const EditInterestsModal = ({ open, onClose, user, onSave }) => {
  const [personalInterests, setPersonalInterests] = useState(user.interessesPessoais?.join(', ') || '');
  const [businessInterests, setBusinessInterests] = useState(user.interessesNegocios?.join(', ') || '');

  const handleSubmit = () => {
    onSave({
      interessesPessoais: personalInterests.split(',').map(i => i.trim()),
      interessesNegocios: businessInterests.split(',').map(i => i.trim()),
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Interesses</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Interesses Pessoais"
          value={personalInterests}
          onChange={(e) => setPersonalInterests(e.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Interesses de Negócios"
          value={businessInterests}
          onChange={(e) => setBusinessInterests(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInterestsModal;