import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, AppBar, Toolbar, IconButton, Grid, Card, CardContent } from '@mui/material';
import { AccountCircle, MonetizationOn, ShoppingBag, Comment, Videocam, Mail } from '@mui/icons-material';

const HomePage = () => {
  return (
    <Container className="home-page">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            ElosCloud
          </Typography>
          <Button color="inherit" component={Link} to="/login">Entrar</Button>
        </Toolbar>
      </AppBar>
      
      <main>
        <section className="hero" style={{ textAlign: 'center', margin: '2rem 0' }}>
          <Typography variant="h3" gutterBottom>Bem-vindo à ElosCloud</Typography>
          <Typography variant="h5">Uma rede social exclusiva para convidados</Typography>
          <Button variant="contained" color="primary" component={Link} to="/login" style={{ marginTop: '1rem' }}>Entrar agora</Button>
        </section>
        
        <section className="features">
          <Typography variant="h4" gutterBottom>Descubra o que você pode fazer na ElosCloud</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><AccountCircle /></IconButton>
                  <Typography>Crie sua conta e registre seus dados</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><MonetizationOn /></IconButton>
                  <Typography>Compre ElosCoin, a moeda virtual da plataforma</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><ShoppingBag /></IconButton>
                  <Typography>Negocie produtos e serviços com outros usuários</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><Comment /></IconButton>
                  <Typography>Faça postagens e receba comentários e reações</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><Comment /></IconButton>
                  <Typography>Receba presentes de seus amigos e conexões</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><Videocam /></IconButton>
                  <Typography>Crie e participe de transmissões ao vivo por vídeo e voz</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><Mail /></IconButton>
                  <Typography>Envie mensagens privadas aos seus contatos</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent style={{ textAlign: 'center' }}>
                  <IconButton color="primary"><Mail /></IconButton>
                  <Typography>Receba ElosCoin ao concluir tarefas e missões</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>

        <section className="call-to-action" style={{ textAlign: 'center', margin: '2rem 0' }}>
          <Typography variant="h4">Entre com a Sua Conta</Typography>
          <Button variant="contained" color="primary" component={Link} to="/login" style={{ marginTop: '1rem' }}>Entrar agora</Button>
        </section>
      </main>
      
      <footer style={{ textAlign: 'center', padding: '1rem 0' }}>
        <Typography>&copy; 2023 ElosCloud. Todos os direitos reservados.</Typography>
      </footer>
    </Container>
  );
};

export default HomePage;